import svg4everybody from 'svg4everybody';
// import $ from 'jquery';
// import validate from "jquery-validation";
// import select2 from '../../node_modules/select2/dist/js/select2.full';
import {Swiper} from 'swiper/swiper-bundle.mjs';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Choices from 'choices.js/public/assets/scripts/choices.js';

// import { Fancybox } from "@fancyapps/fancybox/dist/jquery.fancybox.min.js";
// select2($);

// window.$ = $;
// window.jQuery = $;
// window.validate = validate;
// window.Inputmask = Inputmask;
window.Choices = Choices;
window.Swiper = Swiper;
window.ScrollTrigger = ScrollTrigger;
window.gsap = gsap;
// window.$W = $(window);
// window.$D = $(document);
// window.$H = $('html');
// window.$B = $('body');
// window.Fancybox = Fancybox;

svg4everybody();
// import 'ninelines-ua-parser';

// gsap.registerPlugin(ScrollTrigger);

